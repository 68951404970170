import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { LoadingController, Platform } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../account/account.service';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { md5 } from '../md5';
import { ToastService } from './toast.service';
import { PlatformService } from './platform.service';
import { AccountData } from '../account-data.model';

@Injectable({
  providedIn: 'root'
})
export class SupportService implements OnDestroy {

  private _accountDataSubscription: Subscription;
  private _accountData: AccountData;
  private _appInfo: AppInfo;
  private _chatStatus: string;
  unreaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _toastService: ToastService,
    private _accountService: AccountService,
    private _platform: Platform,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _loadingController: LoadingController,
    private _ngZone: NgZone,
    private _platformService: PlatformService
  ) {
    App.getInfo().then(appInfo=>{
      this._appInfo = appInfo;
    }).catch(()=>{});
    this._accountDataSubscription = this._accountService.accountData.subscribe(accountData=>{
      this._accountData = accountData;
      this._setUserData();
    });
  }

  ngOnDestroy(){
    if(this._accountDataSubscription){
      this._accountDataSubscription.unsubscribe();
    }
  }

  async openChat(){
    this._firebaseAnalyticsService.logEvent('openSupportChat');
    const chatApi = await this.getChatApi();
    if(!chatApi){
      this._toastService.showError('Ошибка! Сбой при открытии чата поддержки');
      return;
    }
    chatApi.open();
  }

  async openChatWithLoader(){
    let loading = await this._loadingController.create({
      message: 'Загрузка...',
      mode: this._platformService.nativeMode
    });
    loading.present();
    this.openChat().finally(()=>{
      loading.dismiss();
    });
  }

  setUnreaded(value: boolean){
    this._ngZone.run(()=>{
      this.unreaded.next(value);
    });
  }

  getChatApi(): Promise<any> {
    const win = window as any;
    if (win.jivo_api) {
      return Promise.resolve(win.jivo_api);
    }

    let promiseResolve;
    let promiseReject;

    win.jivo_onLoadCallback = (() => {
      this._setUserData();
      if(win.jivo_api.getUnreadMessagesCount()>0){
        this.setUnreaded(true);
      }
      promiseResolve(win.jivo_api);
    }).bind(this);

    win.jivo_onChangeState = (status =>{
      this._chatStatus = status;
      if(status=='chat'){
        this.setUnreaded(false);
      }
    }).bind(this);

    win.jivo_onMessageReceived = (() =>{
      if(this._chatStatus=='chat'){
        return;
      }
      this.setUnreaded(true);
    }).bind(this);

    return Network.getStatus().then(status=>{
      if(!status.connected){
        return Promise.reject('no network');
      }
    }).then(()=>{
      return new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;

        const script = document.createElement('script');

        script.onerror = (() => {
          promiseReject('JivoSite API not available');
        }).bind(this);

        script.onload = (() => {
        }).bind(this);

        script.src = `https://code.jivosite.com/widget/${environment.jivosite_widgetId}`;
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);
      });
    })



  }

  private _setUserData(){
    if(!this._accountData){
      return;
    }
    const chatApi = (window as any).jivo_api ?? null;
    if(!chatApi){
      return;
    }
    chatApi.setUserToken( md5(`${this._accountData.id}${environment.jivosite_tokenSalt}`) );
    chatApi.setContactInfo({
      name: `${this._accountData.name} (${this._accountData.id})`,
      email: this._accountData.mail,
    });
    let customData: { //https://www.jivo.ru/api/#setcustomdata
      content?: string,
      title?: string,
      link?: string,
      key?: string
    }[] = [
      {
        key: 'ID',
        content: this._accountData.id.toString(),
        link: `https://medinaschool.org/user/${this._accountData.id}`
      },
      {
        key: 'Курс',
        content: this._accountData.level.toString()
      },
      {
        key: 'Мазхаб',
        content: this._accountService.mazhabList[this._accountData.mazhab]
      },
      {
        title: 'Клиент',
        key: 'Платформа',
        content: this._platform.platforms().join(' ')
      },
    ];
    if(this._appInfo){
      customData.push(
        {
          key: 'Версия',
          content: `${this._appInfo.version} (${this._appInfo.build})`
        },
      );
    }
    chatApi.setCustomData(customData);
  }


}
