// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { FirebaseInitOptions } from '@capacitor-community/firebase-analytics';

export const environment = {
  production: false,

  backendUrl: 'https://medinaschool.org',
  apiUrl: 'https://medinaschool.org/api2',
  api3Url: 'https://medinaschool.org/api3',
  api4Url: 'https://medinaschool.org/api4',
  webPlatformUrl: 'https://web.medinaschool.org',

  // backendUrl: 'https://stage.medinaschool.org',
  // apiUrl: 'https://stage.medinaschool.org/api2',
  // api3Url: 'https://stage.medinaschool.org/api3',
  // api4Url: 'https://stage.medinaschool.org/api4',
  // webPlatformUrl: 'https://web.medinaschool.org',

  donateUrl: 'https://medinaschool.org/donate-ramadan?utm_campaign=medina_2_app&utm_medium=menuitem&utm_source=donate',
  aboutProjectUrl: 'https://medinaschool.org?utm_campaign=medina_2_app&utm_medium=menuitem&utm_source=about_project',
  arabicUrl: 'https://medinaschool.org/arabic?utm_campaign=medina_2_app&utm_medium=menuitem&utm_source=arabic',
  privacyPolicyUrl_login: 'https://medinaschool.org/policy?utm_campaign=medina_2_app&utm_medium=link_user_login_page&utm_source=policy_confidentiality',
  privacyPolicyUrl_settings: 'https://medinaschool.org/policy?utm_campaign=medina_2_app&utm_medium=link_user_account_settings&utm_source=policy_confidentiality',
  jivosite_widgetId: 'TZcRUlGgWa',
  jivosite_tokenSalt: 'medina36c4ve5rbi6ntukh',
  viewTimeout: 10000, // 10sec
  mazhabCardAfterViews: 5,
  // amplitudeApiKey: 'e7b4c1525464c9e9fe8a1f466d87c071',
  amplitudeApiKey: '8570980da557d5f1248455877b1dc303',
  firebaseConfig: <FirebaseInitOptions> {
    apiKey: "AIzaSyAA9UXHc94eAVG23ZeFvDToFMTEWP5DVSU",
    authDomain: "medinaschool-de5aa.firebaseapp.com",
    databaseURL: "https://medinaschool-de5aa.firebaseio.com",
    projectId: "medinaschool-de5aa",
    storageBucket: "medinaschool-de5aa.appspot.com",
    messagingSenderId: "507723827258",
    appId: "1:507723827258:web:6028b2a66a3fb944744d03",
    measurementId: "G-7P6R9GMVG4"
  },
  signInWithAppleOptions: <SignInWithAppleOptions> {
    clientId: 'org.medinaschool.web',
    redirectURI: 'https://medinaschool.org/api2/signInWithApple',
    scopes: 'email name',
    state: '9c674v5ibI&^VRyurv%$*',
  },
  minPasswordLength: 4,
  minWebViewVersion: 63,
  webViewGooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.google.android.webview',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
