import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FullscreenService } from '../services/fullscreen.service';
import { PlayService } from '../../play/play.service';
import { FirebaseAnalyticsService } from '../services/firebase-analytics.service';
import { AmplitudeEventsEnum, AmplitudeService } from '../services/amplitude.service';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent implements OnInit {

  tabsList = [
    {
      title: 'Главная',
      name: 'home',
    },
    {
      title: 'Курсы',
      name: 'courses',
    },
    {
      title: 'Прогресс',
      name: 'map',
    },
    {
      title: 'Урок',
      name: 'play',
    },
  ];

  @Input() currentTab = this.tabsList[0].name;

  @ViewChild('tabBar', {read: ElementRef}) tab: ElementRef;
  tabEl: ElementRef;

  isFullscreen = false;
  showPlayButton = false;

  private _isFullscreenSubscription: Subscription;
  private _contentIdSubscription: Subscription;

  constructor(
    private _renderer: Renderer2,
    private _fullScreenService: FullscreenService,
    private _playService: PlayService,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private amplitudeAnalytics: AmplitudeService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', () => {
        this._renderer.addClass(this.tab.nativeElement, 'hide');
      });
      Keyboard.addListener('keyboardWillHide', () => {
        this._renderer.removeClass(this.tab.nativeElement, 'hide');
      });
    }

    this._isFullscreenSubscription = this._fullScreenService.isFullscreen.subscribe(value => {
      this.isFullscreen = value;
    });

    this._contentIdSubscription = this._playService.contentId.subscribe(contentId => {
      this.showPlayButton = !!contentId;
    });
  }

  ngOnDestroy() {
    if (this._isFullscreenSubscription) {
      this._isFullscreenSubscription.unsubscribe();
    }
    if (this._contentIdSubscription) {
      this._contentIdSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.tab.nativeElement.selectedTab = this.currentTab;
  }

  onTabButtonClick(tab: string) {
    this.handelAnalytics(tab);
    this.router.navigate(['/' + tab]);
  }

  handelAnalytics(tab: string) {
    if (tab === 'play') {
      this.amplitudeAnalytics.trackEvent({
        eventInput: AmplitudeEventsEnum.currentLesson,
      });
    }
    this._firebaseAnalyticsService.logEvent('tabChange', {
      tab
    });
  }

}
