import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
  styleUrls: ['./video-thumbnail.component.scss'],
  animations: [
    trigger('image', [
      state('loading',style({
        opacity: '0',
        transform: 'scale(1.1)'
      })),
      state('ready',style({
        opacity: '1',
        transform: 'none'
      })),
      transition('loading => ready', [
          animate('200ms ease-in')
      ]),
    ]),
  ]
})
export class VideoThumbnailComponent implements OnInit {

  @Input() playIcon: boolean = true;
  @Input() img: string = "./assets/16x9.png";
  @Input() ascpectRatio: number = 16/9;

  @HostBinding('style.--aspect-ratio') get getAscpectRatio(): number{
    return this.ascpectRatio;
  }

  imageState: 'loading' | 'ready' = 'loading';

  constructor() { }

  ngOnInit() {}

  onImgWillLoad(){
    this.imageState = 'loading';
  }
  onImgDidLoad(){
    this.imageState = 'ready';
  }

}
