import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonSearchbar } from '@ionic/angular';
import { EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../account/account.service';
import { FullscreenService } from '../services/fullscreen.service';
import { FirebaseAnalyticsService } from '../services/firebase-analytics.service';
import { SearchResult } from './search-result/search-result.model';
import { SearchType } from './search-type.model';
import { SupportService } from '../services/support.service';
import { MainToolbarAnimations } from './main-toolbar.animations';
import { MainPanelImage } from '../main-panel-image.model';

interface SearchRequestResult {
  result: string,
  query: string,
  type: SearchType,
  results: SearchResult[]
}

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
  animations: MainToolbarAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainToolbarComponent implements OnInit, OnDestroy {
  palmSize:number = 76;
  palmStroke:number = 3;
  @Input('palm') isPalmVisible: boolean = false;
  @Input() title: string;
  @Input() titleLink: string;
  @Input() isAllowSearch = true;
  routerEventsSubscription: Subscription;
  @Input('mainButton') mainButton: 'menu'|'back' = 'menu';
  isAuthenticated: boolean = false;
  myAvatar: string = null;
  myLevel: number = null;
  mainPanelImage: MainPanelImage;
  accountNeedAttention: boolean = false;
  private _accountDataSubscription: Subscription;
  private _authenticatedSubscription: Subscription;
  private _routerEventsSubscription: Subscription;

  menuNeedAttention: boolean = false;
  private _unreadedSupportSubscription: Subscription;

  isFullscreen: boolean = false;
  private _isFullscreenSubscription: Subscription;

  private _isSearch: boolean = false;
  searchResults: SearchResult[] = null;
  @ViewChild('searchInput') searchInput: IonSearchbar;
  isSearchLoading: boolean = false;
  isSearchError: boolean = false;
  isSearchShort: boolean = true;
  searchQuery: string = null;
  @Input('searchType') searchType: SearchType = 'lessons';
  // private _lastNavigatedSearchResultIndex: number;

  scrollReachedTheEnd: boolean = false;

  shakeSearchResults: boolean[] = [];


  constructor(
    private _accountService: AccountService,
    private _router: Router,
    private _fullScreenService: FullscreenService,
    private _httpClient: HttpClient,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _supportService: SupportService
  ) { }

  ngOnInit(){
    this._accountDataSubscription = this._accountService.accountData.subscribe(accountData=>{
      this.isAuthenticated = !!accountData;
      this.myAvatar = accountData ? accountData.avatar : null;
      this.accountNeedAttention = accountData && (accountData.unviewedAnswers>0 || accountData.unreadedNotifications>0);
      this.myLevel = accountData.level ?? null;
      this.mainPanelImage = accountData.mainPanelImage ?? null;
      // console.log('accountData-toolBar', accountData);
    });
    this._isFullscreenSubscription = this._fullScreenService.isFullscreen.subscribe(value=>{
      this.isFullscreen = value;
    });
    //closing search panel when navigate:
    this._routerEventsSubscription = this._router.events.subscribe(event=>{
      if(event instanceof NavigationEnd){
        this.isSearch = false;
      }
    })
    this._unreadedSupportSubscription = this._supportService.unreaded.subscribe(unreaded=>{
      this.menuNeedAttention = unreaded;
    });
  }

  ngOnDestroy(){
    if(this._accountDataSubscription){
      this._accountDataSubscription.unsubscribe();
    }
    if(this._authenticatedSubscription){
      this._authenticatedSubscription.unsubscribe();
    }
    if(this._routerEventsSubscription){
      this._routerEventsSubscription.unsubscribe();
    }
    if(this._isFullscreenSubscription){
      this._isFullscreenSubscription.unsubscribe();
    }
    if(this._unreadedSupportSubscription){
      this._unreadedSupportSubscription.unsubscribe();
    }
  }

  get isSearch():boolean{
    return this._isSearch;
  }

  set isSearch(value: boolean){
    this._isSearch = value;
    if(!this.searchInput?.value || this.searchInput?.value == ''){
      this._resetSearch();
    }
    if(value){
      setTimeout(()=>{
        // if(this.searchResults && this._lastNavigatedSearchResultIndex && this.searchResults[this._lastNavigatedSearchResultIndex]){
        //   document.getElementById(`search-result-${this._lastNavigatedSearchResultIndex}`).scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'center',
        //     inline: 'center',
        //   });
        // }
        if(this.searchInput?.value==''){
          this.searchInput.setFocus();
        }
      }, 150);
    }
  }

  toggleSearch(){
    this.isSearch = !this.isSearch;
  }

  searchReset(){
    this.scrollReachedTheEnd = false;
    this.searchResults = null;
    this.isSearchLoading = false;
    this.isSearchError = false;
    this.isSearchShort = false;
  }

  onSearchSubmit(){
    this.searchReset();
    this.search().subscribe();
  }

  segmentChanged(event: any){
    this.searchReset();
    if(!event?.detail?.value){
      return;
    }
    this.searchType = <SearchType> event.detail.value;
    this.search().subscribe();
  }

  onInfinite(event:any){
    this.search().pipe(
      tap(fetchContentResult=>{
        if(fetchContentResult.results.length<1){
          this.scrollReachedTheEnd = true;
          return;
        }
      }),
      finalize(()=>{
        if(event && event.target && event.target.complete){
          event.target.complete();
        }
      }),
      catchError(error=>{
        if(event && event.target && event.target.cancel){
          event.target.cancel();
        }
        return throwError(error);
      })
    ).subscribe();
  }

  search(): Observable<SearchRequestResult>{
    const searchQuery = this.searchInput.value;
    const searchType = this.searchType;
    if(!searchQuery || searchQuery.length<3 || !searchType){
      this.isSearchShort = true;
      return of(null);
    }
    this._firebaseAnalyticsService.logEvent('search',{
      search_term: searchQuery,
      search_type: searchType
    });
    this.searchInput.getInputElement().then(inputElement=>{
      if(!inputElement){
        return;
      }
      inputElement.blur();
    });
    if(!this.searchResults || this.searchResults.length<1){
      this.isSearchLoading = true;
    }
    return this._httpClient.post<SearchRequestResult>(`${environment.apiUrl}/search`,{
      query:searchQuery,
      type:searchType,
      startFrom: this.searchResults ? this.searchResults.length : 0
    })
    .pipe(
      tap(fetchContentResult => {
        if(!fetchContentResult.result || fetchContentResult.result!='success' || !fetchContentResult.results){
          return throwError(EMPTY);
        }
        this.searchQuery = fetchContentResult.query;
        let routerLink: any[] = [];
        let newResults: SearchResult[] = fetchContentResult.results.map(result=>{
          let available: boolean = true;
          switch(searchType){
            case 'lessons':
              available = result.level && this.myLevel && result.level<=this.myLevel;
              routerLink = available ? ['/play',result.id] : null;
              break;
            case 'webinars':
              routerLink = ['/webinars',result.id];
              break;
            case 'news':
              routerLink = ['/news',result.id];
              break;
          }
          return {
            ...result,
            routerLink: routerLink,
            available: available
          }
        });
        this.searchResults = this.searchResults ? this.searchResults.concat(newResults) : newResults;
      }),
      catchError(error=>{
        return throwError(error);
      }),
      finalize(()=>{
        this.isSearchLoading = false;
      })
    );
  }

  onSearchResultNavigated(index:number){
    // this._lastNavigatedSearchResultIndex = index;
    this.isSearch = false;
  }

  cancelSearch(){
    this.searchInput.value = '';
    this.isSearch = false;
  }

  private _resetSearch(){
    this.searchResults = null;
    this.isSearchError = false;
    this.isSearchLoading = false;
    if (this.searchInput) {
      this.searchInput.value = '';
    }
  }
}
